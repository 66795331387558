import * as requestActions from "../requestActions";

import i18n from '../../lang/';

const room = ({ state }, info) => {
  const action = info.action;

  switch (action?.type) {
    case "SET_ROOM_URL":
      {
        const { url } = action.payload;

        state.room.url = url;
      }

      break;

    case "SET_ROOM_STATE":
      {
        const roomState = action.payload.state;

        if (roomState === "connected") {
          state.room.state = roomState;
        } else {
          state.room = {
            ...state.room,
            state: roomState,
            activeSpeakerId: null,
            statsPeerId: null,
            record: false,
            take_photo_client: false,
            take_record_client: false,
            take_record_client_end: false,
          };
        }
      }

      break;

    case "SET_ROOM_ACTIVE_SPEAKER":
      {
        const { peerId, volume } = action.payload;

        state.room.activeSpeakerId = peerId;
        state.room.volume[peerId] = volume;
      }

      break;

    case "SET_ROOM_STATS_PEER_ID":
      {
        const { peerId } = action.payload;

        if (state.statsPeerId === peerId) {
          state.room.statsPeerId = null;
        }

        state.room.statsPeerId = peerId;
      }

      break;

    case "SET_REFRESH_STATE":
      {
        const { flag } = action.payload;

        state.room.isRefresh = flag;
      }

      break;

    case "SET_FACE_DETECTION":
      {
        const { flag } = action.payload;

        state.room.faceDetection = flag;
      }

      break;

    case "REMOVE_PEER":
      {
        const { peerId } = action.payload;
        const newState = { ...state.room };

        if (peerId && peerId === state.room.activeSpeakerId) {
          newState.activeSpeakerId = null;
        }

        if (peerId && peerId === state.room.statsPeerId) {
          newState.statsPeerId = null;
        }

        state.room = newState;
      }

      break;

    case "SET_RECORD":
      {
        const { status, message } = action.payload;

        if (state.room.record !== status || message) {
          if (status) {
            requestActions.notify({
              text: message || i18n.global.t('notifications.record.start')
            });
          } else {
            requestActions.notify({
              text: message || i18n.global.t('notifications.record.end'),
              type: 'danger',
            });
          }
        }

        state.room.record = status;
      }

      break;

    case "TAKE_PHOTO_CLIENT":
      {
        const { status } = action.payload;

        state.room.take_photo_client = status;
      }

      break;

    case "TAKE_RECORD_CLIENT":
      {
        const { status } = action.payload;

        state.room.take_record_client = status;
      }

      break;

    case "TAKE_RECORD_CLIENT_END":
      {
        const { status } = action.payload;

        state.room.take_record_client_end = status;

        requestActions.notify({
          text: i18n.global.t('notifications.record.end'),
          type: 'danger',
        });
      }

      break;
  }
};

export default room;
