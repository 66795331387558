import { createRouter, createWebHistory } from "vue-router";

import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "welcome",
    props: true,
    component: () => import("../components/Welcome.vue"),
  },
  {
    path: "/close",
    name: "close",
    props: true,
    component: () => import("../components/Thankyoupage.vue"),
  },
  {
    path: "/sign",
    name: "sign",
    props: true,
    component: () => import("../components/Signpage.vue"),
  },
  {
    path: "/reserved",
    name: "reserved",
    props: true,
    component: () => import("../components/Bookingpage.vue"),
  },
  {
    path: "/unavailable",
    name: "unavailable",
    props: true,
    component: () => import("../components/UnavailablePage.vue"),
  },
  {
    path: "/room/:roomId",
    name: "room",
    props: true,
    component: () => import("../components/Room.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
